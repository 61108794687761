<template>
  <div>
    <b-row class="m-0 w-100 mb-1" v-if="!data_header.is_in_sidebar">
      <div :class="`${!isChatRoute ? 'col-12 col-md-8 p-0' : 'col-12'}`">
        <div class="categories-subcategories position-relative d-flex align-items-center">
          <b-link @click="redirectLastRoute($router)" variant="text-work-type" class="text-work-type" v-if="!isChatRoute">
            <strong>
              {{ $t('tracking.goBack') }}
            </strong>
          </b-link> <span v-if="!isChatRoute">|</span>
          <b-link variant="text-work-type" class="text-work-type" @click="redirectionProposals('work_types', data_header.work_type)">{{getTranslateType(data_header.work_type)}}</b-link>
          <feather-icon icon="ChevronRightIcon"></feather-icon>
          <b-link 
            @click="redirectionProposals('content_type', data_header.content_type)"
            :variant="data_header.content_type ? 'text-work-type' : 'warning'" 
            :class="data_header.content_type ? 'text-work-type' : ''"
          >{{data_header.content_type ? getTranslateContent(data_header.content_type) : $t('creator.no_content_type')}}</b-link>

          <div class="background-button-edit" @click="$emit('edit_proposal', 'categories')" v-if="is_avaible_to_editing && !data_header.is_in_sidebar">
            <h5 class="m-0 text-white animation-opacity-words">{{$t('creator.editCategory')}}</h5>
          </div>
        </div>
      </div>
      <b-col class="d-none d-md-block col-4 p-0 pl-2" v-if="is_avaible_to_editing && !data_header.is_in_sidebar">
        <div class="bubble-edit" @click="$emit('edit_proposal', 'categories')">
          <feather-icon icon="Edit2Icon"></feather-icon>
          <strong class="ml-05 avenir-bold">{{$t('creator.catSubcat')}}</strong>
        </div>        
      </b-col>
    </b-row>
    <b-row v-if="data_header.images.length > 0" class="m-0">
      <b-col :class="`${is_avaible_to_editing && !data_header.is_in_sidebar ? 'col-12 col-md-8' : 'col-12'} p-0 grid`" :key="update_image">
        <div 
          :class="`${getClassForAmountImages(index, images)} container-image-grid`" 
          v-for="(image, index) in images" 
          :key="index"
          @click="openSwiper(index)"
        > 
          <!-- Normal Image -->
          <b-img 
            v-if="!isVideo(image.media)" 
            :src="isUrl(image.media) || image.error_to_load ? image.media : getAssetUrl(image.media)" 
            square 
            class="avatar-preview-header-steps"
            @error="errorLoad(image.media)"
          ></b-img>
          <!-- Preview Video -->
          <b-img 
            v-else-if="isVideo(image.media) && image.preview.length > 0" 
            :src="isUrl(image.preview) || image.error_to_load ? image.preview : getAssetUrl(image.preview)" 
            square 
            class="avatar-preview-header-steps"
            @error="errorLoad(image.media)"
          ></b-img>
          <!-- In case it does not have a preview of video -->
          <video
            v-else
            muted 
            :src="isUrl(image.media) ? image.media : getAssetUrl(image.media) " 
            class="avatar-preview-header-steps"
          ></video>
          <div class="main-container-icon-hover">
            <div class="icon-hover-grid">
              <div>
                <b-avatar icon="play-fill" class="text-success" variant="light" size="20px" v-if="isVideo(image.media)"></b-avatar>
              </div>
              <b-avatar icon="arrows-fullscreen" class="text-dark" variant="light" size="20px"></b-avatar>
            </div>
          </div>
        </div>
        <b-button class="button-see-more-images" variant="light" @click="open_modal_images = true; update_modal_images += 1" v-if="data_header.images.length > 1">{{ $t('creator.seeAll') }} {{data_header.images.length}} {{ $t('creator.images') }}</b-button>
        <div class="background-button-edit" @click="$emit('edit_proposal', 'samples')" v-if="is_avaible_to_editing && !data_header.is_in_sidebar">
          <h5 class="text-white animation-opacity-words">{{$t('creator.editSamples')}}</h5>
        </div>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2" v-if="is_avaible_to_editing && !data_header.is_in_sidebar">
        <div class="bubble-edit" @click="$emit('edit_proposal', 'samples')">
          <feather-icon icon="Edit2Icon"></feather-icon>
          <strong class="ml-05 avenir-bold">{{$t('creator.samplesStep')}}</strong>
        </div>        
      </b-col>
    </b-row>
    
    <!-- Box when samples length === 0 -->
    <b-row class="m-0 w-100 mb-2 pl-0" v-else-if="is_avaible_to_editing && !data_header.uploaded_images">
      <b-col class="col-12 col-md-8 p-0 m-0 h-100">
        <b-button  class="add-option-action" variant="add-option-action" @click="$emit('edit_proposal', 'samples')">
          <h4 class="avenir-bold">{{$t('creator.samplesStep')}}</h4>
          <p class="avenir-medium m-0">{{$t('creator.mediaAdd')}}</p>
          <div class="box-add-option-absolute avenir-bold">
            {{$t('creator.editSamples')}}
          </div>
        </b-button>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2 h-100">
        <div class="bubble-edit red-edit-bubble h-100" @click="$emit('edit_proposal', 'samples')">
          <div>
            <b-icon icon="asterisk"></b-icon>
            <strong class="ml-05 avenir-bold">{{$t('creator.samplesStep')}}</strong>
            <p class="avenir-medium m-0">{{$t('creator.mediaReq')}}</p>
          </div>
        </div>        
      </b-col>
    </b-row>

    <b-row class="m-0 w-100 mb-2" v-else-if="data_header.uploaded_images">
      <b-col class="col-12 col-md-8 p-0 m-0 h-100">
        <b-skeleton class="skeleton-generated-media-samples"></b-skeleton>
        <div class="p-1 text-left">
          <h4 class="avenir-bold"> {{$t('creator.samplesStep')}}</h4>
          <p class="avenir-medium m-0">{{$t('creator.samplesGenerated')}}</p>
        </div>
      </b-col>
      <b-col class="d-none d-md-block col-4 p-0 pl-2 h-100">
             
      </b-col>
    </b-row>
    <!-- -------------------- -->
    <b-row class="mt-1 mb-1 pl-0 pr-1">
      <b-row class="m-0 w-100">
        <b-col 
          :class="{
            'col-12 col-md-8': (is_avaible_to_editing && !data_header.is_in_sidebar), 
            'col-12 col-lg-8': !is_avaible_to_editing && !data_header.is_in_sidebar,
            'col-12': !data_header.is_in_sidebar
          }" class="p-0">
          <div class="d-flex flex-wrap w-100 title-description-edit position-relative">
            <h2 :class="`${is_avaible_to_editing || data_header.is_in_sidebar ? 'col-12' : 'col-lg-12' } w-100 text-left color-text-title`"  v-if="!data_header.loaded_ia_title">{{data_header.title}}</h2>
            <div v-else class="w-100">
              <b-skeleton animation="fade" width="100%"></b-skeleton>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
            </div>
            <p :class="`${is_avaible_to_editing || data_header.is_in_sidebar ? 'col-12' : 'col-lg-12' } w-100 text-left mt-1 avenir-medium color-text-description`" v-if="!data_header.loaded_ia_description">{{data_header.description}}</p>
            <div class="w-100 mt-2" v-else>
              <b-skeleton animation="fade" width="100%"></b-skeleton>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="70%"></b-skeleton>
              <b-skeleton animation="fade" width="100%"></b-skeleton>
            </div>

            <!-- Metrics of network -->
            <b-row class="m-0 w-100 mt-1">
              <div class="col-12">
                <h4 class="text-left ">{{ $t('creator.about2') }} @{{ data_header.user_network.username }}</h4>
                <b-link :href="getLinkNetwork(data_header.user_network)" target="_blank" class="text-left avenir-medium d-block mb-1">{{ $t('creator.view_on') }} {{ nameNetworks(data_header.user_network.network) }}</b-link>
              </div>
              <b-col class="px-1 mb-1 col-12 col-sm-6">
                <div class="border-box-analytics p-2">
                  <p class="text-uppercase">{{ $t('lists.followers') }}</p>
                  <strong>{{ analytics_overview ? analytics_overview.followers : getFormat(data_header.user_network.followers) }}</strong>
                </div>
              </b-col>
              <b-col class="mb-1 col-12 col-sm-6">
                <div class="border-box-analytics p-2">
                  <p>{{$t('creator.avg_er_post')}}</p>
                  <strong v-if="analytics_overview">{{`${analytics_overview.er}%`}}</strong>
                  <div v-else><feather-icon icon="LockIcon" class="lock-icon-charts"/></div>
                </div>
              </b-col>
              <b-link v-if="data_header.user_network.vanity_name" @click="redirectAnalytics(data_header.user_network.vanity_name)" class="d-block col-12 avenir-medium text-right">{{ $t('creator.seeAllMetrics') }}</b-link>
            </b-row>
            <!-- ---- -->
            <div class="background-button-edit" @click="$emit('edit_proposal', 'title_description')" v-if="is_avaible_to_editing && !data_header.is_in_sidebar">
              <h5 class="m-0 text-white animation-opacity-words">{{ $t('creator.editTitle') }}</h5>
            </div>
          </div>
        </b-col>
        <b-col class="d-none d-md-block col-4 p-0 pl-2 mt-1" v-if="is_avaible_to_editing && !data_header.is_in_sidebar">
          <div class="bubble-edit" @click="$emit('edit_proposal', 'title_description')">
            <feather-icon icon="Edit2Icon"></feather-icon>
            <strong class="ml-05 avenir-bold">{{ $t('creator.titleDesc') }}</strong>
          </div>
        </b-col>
      </b-row>
    </b-row>

    <swiper-brandme 
      v-if="open_modal_images" 
      :open_modal_images="open_modal_images" 
      :key="update_modal_images" 
      :swiper_data="swiper_data"
      :current_slide_index="index_image_swiper"
    ></swiper-brandme>

    <modal-login :open_modal="open_modal_login" :key="update_modal_login"></modal-login>
  </div>  
</template>

<script>
import {
  BCol,
  BRow,
  BAvatar,
  BImg,
  BButton,
  BLink,
  BSkeleton,
} from 'bootstrap-vue';
import swiperBrandme from '@/views/components/swiper-brandme/swiperBrandme.vue';
import { redirectLastRoute } from '@/libs/utils/others'
import { nameNetworks, getFormat, isVideo } from '@/libs/utils/formats'
import { getURL, getAssetUrl, isUrl } from '@/libs/utils/urls';
import { getUserData } from '@/libs/utils/user';
import profile_services from '@/services/profile';
import service_others from '@/services/others';

export default {
  name: 'headerPreviewSteps',
  components: {
    BCol,
    BRow,   
    BAvatar,
    BImg,
    BLink,
    BButton,
    swiperBrandme,
    BSkeleton,
    ModalLogin: () => import('@/views/components/modal/ModalLogin.vue')
  },
  data() {
    return {
      isUrl,
      getAssetUrl,
      isVideo,
      nameNetworks,
      getFormat,
      redirectLastRoute,
      isChatRoute: false,
      open_modal_images: false,
      update_modal_images: 0,
      update_image: false,
      images: [],
      index_image_swiper: 0,
      swiper_data: [],
      analytics_overview: null,
      open_modal_login: false,
      update_modal_login: false,
    }
  },
  props: {
    data_header: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  created() {
    this.getImages();
    if (this.$route.matched.some(route => route.path === '/proposal/chat/:chat_uuid?')) {
      this.isChatRoute = true;
    }
    if (this.has_session && this.isAvaibleToAnalize) this.getReportNetwork();
  },
  computed: {
    is_avaible_to_editing() {
      return (this.data_header.state !== 'published' && this.data_header.is_your_proposal) || this.data_header.is_editing
    },
    is_brand() {
      return getUserData() && getUserData().main_group.name !== 'creator'
    },
    has_session() {
      return getUserData()
    },
    isAvaibleToAnalize() {
      const networks_avaible = ['instagram', 'youtube', 'tiktok', 'twitch', 'twitter']
      return networks_avaible.includes(this.data_header.user_network.network)
    },
  },
  methods: {
    getReportNetwork() {
      if (this.is_brand) {
        service_others.getVisitedProfiles(this.data_header.user_network.network, this.data_header.user_network.username).then((response) => {
          if (response.results.length > 0) {
            this.assignMetricsFromUserNetwork();
          }
        })
      } else {
        profile_services.canUnlockOrPayProfile(this.data_header.user_network.uuid).then((response) => {
          if (response.already_viewed) {
            this.assignMetricsFromUserNetwork()
          }
        })
      }
    },
    assignMetricsFromUserNetwork() {
      this.analytics_overview = {
        followers: getFormat(this.data_header.user_network.followers),
        er: this.data_header.user_network.er,
      } 
    },
    getLinkNetwork(profile) {
      if (profile.profile_url) return profile.profile_url
      else if (profile.network === 'facebook' && profile.link) return profile.link
      return getURL(profile.network, profile.username)
    },
    redirectAnalytics(vanity_name) {
      if (this.has_session) {
        const url = `${window.location.origin}/@${vanity_name}`
        window.open(url, '_blank');
      } else {
        this.open_modal_login = true;
        this.update_modal_login = !this.update_modal_login;
      }
    },
    redirectionProposals(key_name, value) {
      this.$router.push({name: 'search_proposal', query: {[key_name]: value}});
      this.$root.$emit('update_proposals');
    },
    errorLoad(image) {
      const image_error = this.data_header.images.find((i) => i.media === image);
      image_error.media = require("@/assets/images/dashboard/imgDefault.png");
      image_error.error_to_load = true;
    },
    openSwiper(index) {
      this.open_modal_images = true;
      this.update_modal_images += 1;
      this.index_image_swiper = index;
    },
    getImages() {
      this.images = this.data_header.images.sort((a, b) => a.sort - b.sort).slice(0, 5);
      const images_swiper = [];
      this.data_header.images.sort((a, b) => a.sort - b.sort).map((item) => {
        isVideo(item.media) ? images_swiper.push(
          {video: !isUrl(item.media) ? getAssetUrl(item.media) : item.media}
        ) : images_swiper.push({img: !isUrl(item.media) ? getAssetUrl(item.media) : item.media})
      });
      this.swiper_data = images_swiper;
    },
    getClassForAmountImages(index, images) {
      if (images.length === 5) {
        return `box-image-${index}`
      } else if (images.length === 1) {
        return `box-image-one`
      } else if (images.length === 2) {
        return `box-image-two-${index}`
      } else if (images.length === 3) {
        return `box-image-three-${index}`
      } else if (images.length === 4) {
        return `box-image-four-${index}`
      } 
    },
    getTranslateType(text) {
      return this.$t(`creator.type_of_work`).find((type) => type.value === text).text;
    },
    getTranslateContent(text) {
      return this.$t(`creator.focus_in_${this.data_header.work_type}`).find((type) => type.value === text).text;
    }
  }
}
</script>
<style scoped lang="scss">
.border-box-analytics {
  border: 1px solid #eaeaea;
  border-radius: 1em;
  padding: 0.5em;
  box-shadow: 0rem 0.375rem 0.75rem rgb(108 117 125 / 8%);
  text-align: left;
  p {
    font-family: "avenir-medium";
    font-size: 11.5px;
    color: #6c757d;
  }
}
.text-work-type {
  color: #3483fa;
  margin-right: 5px;
  margin-left: 5px;
} 
.text-work-type:hover {
  color: #2968c8;
  cursor: pointer;
} 

.skeleton-generated-media-samples {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.color-text-title {
  color: #191919
}
.color-text-description {
  color: #1e2124;
  white-space: pre-line;
}
.container-image-grid {
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-image-grid:hover {
  cursor: pointer;
  position: relative;
}
.container-image-grid:hover .main-container-icon-hover {
  transition: all 500ms;
  display: block;
}
.main-container-icon-hover {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}
.icon-hover-grid {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  transition: all 500ms;
}
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 190px);
  gap: 10px;
  row-gap: 0px;
  position: relative;
}

.background-button-edit {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 0.55em;
}
.animation-opacity-words {
  opacity: 0;
}
.grid:hover .background-button-edit,
.categories-subcategories:hover .background-button-edit,
.title-description-edit:hover .background-button-edit
{
  background-color: #3b8da888;
  transition: all 500ms;
  cursor: pointer;
  backdrop-filter: blur(10px);
}
.grid:hover .animation-opacity-words,
.categories-subcategories:hover .animation-opacity-words,
.title-description-edit:hover .animation-opacity-words
{
  opacity: 1;
  transition: all 500ms;
}
.grid:not(:hover) .background-button-edit, 
.grid:not(:hover) .animation-opacity-words,
.categories-subcategories:not(:hover) .animation-opacity-words,
.categories-subcategories:not(:hover) .background-button-edit,
.title-description-edit:not(:hover) .animation-opacity-words,
.title-description-edit:not(:hover) .background-button-edit
{
  transition: all 500ms;
}
.categories-subcategories, .title-description-edit {
  padding: 1em 0 0 0;
}
.bubble-edit {
  text-align: left;
  /* position: relative; */
  /* height: auto; */
  /* width: 100%; */
  padding: 1em;
  color: #3b8DA8;
  background: #E6f2f6;
  border-radius: 1em;
  cursor: pointer;
  position: sticky  ;
  position: -webkit-sticky;
  top: 12em;
}
.bubble-edit:hover {
  transition: all 300ms;
  background: #3b8da82f;
}
.bubble-edit:before {
  border: 10px solid #E6f2f6;
  content: '';
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  transform: rotate(90deg);
  left: -20px;
}
.bubble-edit:hover:before {
  border: 10px solid #3b8da82f;
  content: '';
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  transform: rotate(90deg);
  left: -20px;
  transition: all 300ms;
}
/* red bubble */
.red-edit-bubble {
  color: #2969C8 !important;
  background: #2969c81e !important;
  min-height: 75.5px !important;
}
.red-edit-bubble:before {
  border: 10px solid #2969c81e !important;
  content: '' !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  position: absolute !important;
  transform: rotate(90deg) !important;
  left: -20px !important;
}
.red-edit-bubble:hover {
  background-color: #2969c83a !important;
}
.red-edit-bubble:hover:before {
  border: 10px solid #2969c83a !important;
  content: '' !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  position: absolute !important;
  transform: rotate(90deg) !important;
  left: -20px !important;
}
.add-option-action {
  border: 2px dashed #2969C8;
  color: #2969C8;
}
.add-option-action h4 {
  color: #e11717;
}
.box-add-option-absolute {
  background-color: #2969C8;
}
.add-option-action:hover {
  border: 2px solid #2969C8;
}
.h71{
  height: 71px;
}
/* -------------------- */
.ml-05 {
  margin-left: 0.55em;
}
.h-100-maxx {
  height: 380px !important;
}
.box-image-0 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
.box-image-1 {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
}
.box-image-2 {
  grid-column: 4 / 5;
  grid-row: 1;
  margin-bottom: 5px;
}
.box-image-3 {
  grid-column: 4 / 5;
  grid-row: 2;
  margin-top: 5px;
}
.box-image-4 {
  grid-column: 5 / 6;
  grid-row: 1 / 3;
}
.box-image-four {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}
.box-image-one {
  grid-column: 1 / 6;
  grid-row: 1 / 3;
} 
.box-image-two-0 {
  grid-column: 1 / 4;
  grid-row: 1 / 3;
} 
.box-image-two-1 {
  grid-column: 4 / 6;
  grid-row: 1 / 3;
} 
.box-image-three-0 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
} 
.box-image-three-1 {
  grid-column: 3 / 5;
  grid-row: 1 / 3;
} 
.box-image-three-2 {
  grid-column: 5 / 6;
  grid-row: 1 / 3;
}
.box-image-four-0 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
.box-image-four-1 {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
} 
.box-image-four-2 {
  grid-column: 4 / 5;
  grid-row: 1 / 3;
} 
.box-image-four-3 {
  grid-column: 5 / 6;
  grid-row: 1 / 3;
} 
.avatar-preview-header-steps {
  width: 100%;
  height: 100%;
  max-height: 380px;
  object-fit: cover;
}
.header-preview-steps {
  height: 380px !important;
  position: relative;
}
.p-02 {
  padding: 0.25em !important;
}
.no-images-avatar {
  width: 100%;
  height: 380px;
  object-fit: cover;
}
.button-see-more-images {
  position: absolute;
  right: 2em;
  z-index: 10;
  bottom: 2em;
}
.light-secondary-v2 {
  background-color: #e8e8e8;
  color: #718096;
  font-weight: 400;
}
</style>
